
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { AlertComponentInterface } from "../components/interfaces";
import { LazyLoad } from "./../components/LazyLoad";

@Component({
	components: {
		ProfileSetupForm: () => LazyLoad(import(/* webpackChunkName: "profile-setup-form" */ "./../components/forms/ProfileSetupForm.vue")),
		SuccessRedirect: () => LazyLoad(import(/* webpackChunkName: "success-redirect" */ "./../components/SuccessRedirect.vue")),
	},
})
export default class SetupProfile extends Vue {
	@Prop({ default: {} })
	public query!: Record<string, any>;

	public actions = {
		setup: this.$url(process.env.VUE_APP_PROFILE_SETUP_ACTION as string, this.query),
	};

	@Prop({ required: true })
	public countries!: Record<string, string>;

	@Prop({ required: true })
	public info!: Record<string, string>;

	@Ref("alert")
	public readonly $alert!: AlertComponentInterface;

	@Ref("success")
	public readonly $success!: InstanceType<any>;

	public step: "setup" | "complete" = "setup";
	public result: Record<"begin" | "verification" | "complete", any> = {
		begin: {},
		verification: {},
		complete: {},
	};

	public stateNext: string | null = null;

	created() {
		console.log("Return url", this.returnUrl);
		console.log("Return url is internal? ", this.$isInternalUrl(this.returnUrl));
	}

	public get returnUrl() {
		if (this.stateNext) {
			return this.stateNext;
		}
		const home = process.env.VUE_APP_HOME_URL as string;
		if (this.query.continue && this.$isInternalUrl(this.query.continue)) {
			return this.query.continue as string;
		}
		return home;
	}

	onSetupDone({ data }: any) {
		console.log("Data ===>", data);
		this.$store.commit("SET_CURRENT_USER", data.user);
		if (data.next) {
			this.stateNext = data.next;
		}
		this.showComplete();
	}

	showComplete() {
		this.step = "complete";
		this.$success.count();
	}
}
